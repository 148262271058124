/* ////////////////////////////// Global styles ////////////////////////////// */

@import 'variables';
@import 'primengOverrides';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~bootstrap/dist/css/bootstrap.css';

/* //////////////////// Atomic //////////////////// */

// Display //

.displayBlock {
  display: block;
}

.displayFlex {
  display: flex;
}

.displayNone {
  display: none;
}

// Margin //

.margin_0 {
  margin: 0 !important;
}

.marginTop_0 {
  margin-top: 0 !important;
}

.marginLeft_0 {
  margin-left: 0 !important;
}

.marginBottom_0 {
  margin-bottom: 0 !important;
}

.marginRight_0 {
  margin-right: 0 !important;
}

.margin_1 {
  margin: 1rem !important;
}

.marginTop_1 {
  margin-top: 1rem !important;
}

.marginLeft_1 {
  margin-left: 1rem !important;
}

.marginBottom_1 {
  margin-bottom: 1rem !important;
}

.marginRight_1 {
  margin-right: 1rem !important;
}

.margin_2,
.marginR_2 {
  margin: 1.5rem !important;
}

.marginTop_2,
.marginTopR_2 {
  margin-top: 1.5rem !important;
}

.marginLeft_2,
.marginLeftR_2 {
  margin-left: 1.5rem !important;
}

.marginBottom_2,
.marginBottomR_2 {
  margin-bottom: 1.5rem !important;
}

.marginRight_2,
.marginRightR_2 {
  margin-right: 1.5rem !important;
}

.margin_3 {
  margin: 2rem !important;
}

.marginTop_3 {
  margin-top: 2rem !important;
}

.marginLeft_3 {
  margin-left: 2rem !important;
}

.marginBottom_3 {
  margin-bottom: 2rem !important;
}

.marginRight_3 {
  margin-right: 2rem !important;
}

@media screen and (max-width: $mediaqueryS) {
  .marginR_2 {
    margin: 1rem !important;
  }

  .marginTopR_2 {
    margin-top: 1rem !important;
  }

  .marginLeftR_2 {
    margin-left: 1rem !important;
  }

  .marginBottomR_2 {
    margin-bottom: 1rem !important;
  }

  .marginRightR_2 {
    margin-right: 1rem !important;
  }
}

// Padding //

.padding_0 {
  padding: 0 !important;
}

.paddingTop_0 {
  padding-top: 0 !important;
}

.paddingLeft_0 {
  padding-left: 0 !important;
}

.paddingBottom_0 {
  padding-bottom: 0 !important;
}

.paddingRight_0 {
  padding-right: 0 !important;
}

.padding_1 {
  padding: 1rem !important;
}

.paddingTop_1 {
  padding-top: 1rem !important;
}

.paddingLeft_1 {
  padding-left: 1rem !important;
}

.paddingBottom_1 {
  padding-bottom: 1rem !important;
}

.paddingRight_1 {
  padding-right: 1rem !important;
}

.padding_2 {
  padding: 1.5rem !important;
}

.paddingTop_2 {
  padding-top: 1.5rem !important;
}

.paddingLeft_2 {
  padding-left: 1.5rem !important;
}

.paddingBottom_2 {
  padding-bottom: 1.5rem !important;
}

.paddingRight_2 {
  padding-right: 1.5rem !important;
}

// Font //

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.fontNormal {
  font-weight: normal;
}

.fontBold {
  font-weight: bold;
}

.colorText {
  color: $colorText;
}

.colorTextLight {
  color: $colorTextLight;
}

// Images //

.imgResponsive {
  width: 100%;
  height: auto;
}

/* //////////////////// Utilities //////////////////// */

.fullWidth {
  width: 100%;
}

.divider {
  width: 100%;
  border-bottom: 1px solid $colorOutline;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: none;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

.unstyledButton {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: transparent;
  color: $colorText;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px $colorTextLight;
  }
}

@mixin unstyledButton {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  font-family: Heebo, sans-serif;
  text-decoration: none;
  background: transparent;
  color: $colorText;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px $colorTextLight;
  }
}

.greyBkg {
  min-height: calc(100vh - (6rem + 48px));
  background-color: $colorBkg;
}

/* //////////////////// Custom scrollbar //////////////////// */

$scrollbarSize: 0.5rem;
$scrollbarMinLength: 1.5rem;
$scrollbarFirefoxWidth: thin;
$scrollbarTrackColor: rgba(0, 0, 0, 0.05);
$scrollbarColor: rgba(0, 0, 0, 0.3);
$scrollbarColorHover: rgba(0, 0, 0, 0.5);
$scrollbarColorActive: rgb(0, 0, 0);

.elementScrollX {
  overscroll-behavior: contain;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: $scrollbarFirefoxWidth;
}

.elementScrollY {
  overscroll-behavior: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: $scrollbarFirefoxWidth;
}

.customScrollbar {
  scrollbar-color: $scrollbarColor $scrollbarTrackColor;

  &::-webkit-scrollbar {
    height: $scrollbarSize;
    width: $scrollbarSize;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbarTrackColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbarColor;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbarColorHover;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $scrollbarColorActive;
  }

  &::-webkit-scrollbar-thumb:vertical {
    min-height: $scrollbarMinLength;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    min-width: $scrollbarMinLength;
  }
}

/* //////////////////// General //////////////////// */

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  padding-bottom: 0;
}

body {
  font-family: Heebo, sans-serif;
  color: $colorText;
}

/* //////////////////// Text //////////////////// */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Overpass, sans-serif;
  font-weight: bold;
  line-height: 1.15;
}

/* //////////////////// Grid //////////////////// */

.container,
.container_fluid {
  max-width: $containerWidthL;
  margin: 0 auto;
  padding: 0 $containerGutter;
}

.container_fluid {
  max-width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$containerGutter;
  margin-right: -$containerGutter;
}

.col,
.col_1,
.col_2,
.col_3,
.col_4,
.col_5,
.col_6,
.col_7,
.col_8,
.col_9,
.col_10,
.col_11,
.col_12,
.col_lg_1,
.col_lg_2,
.col_lg_3,
.col_lg_4,
.col_lg_5,
.col_lg_6,
.col_lg_7,
.col_lg_8,
.col_lg_9,
.col_lg_10,
.col_lg_11,
.col_lg_12 {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding: 0 $containerGutter;
}

.col {
  flex: 1 0 0px;
}

.col_1,
.col_2,
.col_3,
.col_4,
.col_5,
.col_6,
.col_7,
.col_8,
.col_9,
.col_10,
.col_11,
.col_12 {
  flex: 0 0 auto;
}

.col_1 {
  width: 8.33333%;
}

.col_2 {
  width: 16.66666%;
}

.col_3 {
  width: 25%;
}

.col_4 {
  width: 33.33333%;
}

.col_5 {
  width: 41.66666%;
}

.col_6 {
  width: 50%;
}

.col_7 {
  width: 58.33333%;
}

.col_8 {
  width: 66.66666%;
}

.col_9 {
  width: 75%;
}

.col_10 {
  width: 83.33333%;
}

.col_11 {
  width: 91.66666%;
}

.col_12 {
  width: 100%;
}

@media screen and (min-width: $mediaqueryM) {
  .col_lg_1,
  .col_lg_2,
  .col_lg_3,
  .col_lg_4,
  .col_lg_5,
  .col_lg_6,
  .col_lg_7,
  .col_lg_8,
  .col_lg_9,
  .col_lg_10,
  .col_lg_11,
  .col_lg_12 {
    flex: 0 0 auto;
  }

  .col_lg_1 {
    width: 8.33333%;
  }

  .col_lg_2 {
    width: 16.66666%;
  }

  .col_lg_3 {
    width: 25%;
  }

  .col_lg_4 {
    width: 33.33333%;
  }

  .col_lg_5 {
    width: 41.66666%;
  }

  .col_lg_6 {
    width: 50%;
  }

  .col_lg_7 {
    width: 58.33333%;
  }

  .col_lg_8 {
    width: 66.66666%;
  }

  .col_lg_9 {
    width: 75%;
  }

  .col_lg_10 {
    width: 83.33333%;
  }

  .col_lg_11 {
    width: 91.66666%;
  }

  .col_lg_12 {
    width: 100%;
  }
}

/* //////////////////// Button //////////////////// */

/* ////////// Base ////////// */

.button {
  padding: 0.625rem 1rem;
  border: 2px solid $colorMain;
  border-radius: 0;
  font-size: 1rem;
  font-weight: normal;
  color: $colorTextInverse;
  background-color: $colorMain;
  box-shadow: none;
  outline: none;
  transition: border 200ms, color 200ms, background-color 200ms, box-shadow 200ms;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    border-color: $colorMain_light;
    background-color: $colorMain_light;
  }

  &:active {
    border-color: $colorMain_dark;
    background-color: $colorMain_dark;
  }

  &:focus {
    box-shadow: 0 0 0 3px $colorMain_shadow;
    outline: none;
  }

  i {
    line-height: 1;
  }
}

.buttonIcon_right {
  margin-right: 0.375rem;
}

/* ////////// Color ////////// */

.button_colorAlt {
  border-color: $colorMainAlt;
  background-color: $colorMainAlt;

  &:hover {
    border-color: $colorMainAlt_light;
    background-color: $colorMainAlt_light;
  }

  &:active {
    border-color: $colorMainAlt_dark;
    background-color: $colorMainAlt_dark;
  }

  &:focus {
    box-shadow: 0 0 0 3px $colorMainAlt_shadow;
  }
}

.button_colorSuccess {
  border-color: $colorSuccess;
  background-color: $colorSuccess;

  &:hover {
    border-color: $colorSuccess_light;
    background-color: $colorSuccess_light;
  }

  &:active {
    border-color: $colorSuccess_dark;
    background-color: $colorSuccess_dark;
  }

  &:focus {
    box-shadow: 0 0 0 3px $colorSuccess_shadow;
  }
}

.button_colorWarning {
  border-color: $colorWarning;
  color: $colorText;
  background-color: $colorWarning;

  &:hover {
    border-color: $colorWarning_light;
    background-color: $colorWarning_light;
  }

  &:active {
    border-color: $colorWarning_dark;
    background-color: $colorWarning_dark;
  }

  &:focus {
    box-shadow: 0 0 0 3px $colorWarning_shadow;
  }
}

.button_colorDanger {
  border-color: $colorDanger;
  background-color: $colorDanger;

  &:hover {
    border-color: $colorDanger_light;
    background-color: $colorDanger_light;
  }

  &:active {
    border-color: $colorDanger_dark;
    background-color: $colorDanger_dark;
  }

  &:focus {
    box-shadow: 0 0 0 3px $colorDanger_shadow;
  }
}

.button_colorGrey {
  border-color: $colorDanger;
  background-color: $colorDanger;

  &:hover {
    border-color: $colorDanger_light;
    background-color: $colorDanger_light;
  }

  &:active {
    border-color: $colorDanger_dark;
    background-color: $colorDanger_dark;
  }

  &:focus {
    box-shadow: 0 0 0 3px $colorDanger_shadow;
  }
}

/* ////////// Block ////////// */

.button_block {
  width: 100%;
}

/* ////////// Big ////////// */

.button_big {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
}

/* ////////// Small ////////// */

.button_small {
  font-size: 0.875rem;
}

/* ////////// Outline ////////// */

.button_outline {
  border-color: rgba(3, 22, 64, 0.2);
  color: $colorMain;
  background-color: transparent;

  &:hover {
    border-color: $colorMain;
    color: white;
    background-color: $colorMain;
  }

  &:active {
    border-color: $colorMain_light;
    color: white;
    background-color: $colorMain_light;
  }

  &:focus {
    box-shadow: 0 0 0 3px $colorMain_shadow;
  }

  &.button_colorGrey {
    border-color: $colorOutline;
    color: $colorText;

    &:hover {
      border-color: $colorOutline;
      color: $colorText;
      background-color: $colorBkg;
    }

    &:active {
      border-color: $colorOutline;
      color: $colorText;
      background-color: $colorOutline;
    }

    &:focus {
      box-shadow: 0 0 0 3px $colorTextLight_shadow;
    }
  }
}

/* ////////// Clear ////////// */

.button_clear {
  padding: 0.25rem 0.375rem;
  font-weight: bold;
  color: $colorMain;
  background-color: transparent;

  &:hover {
    color: $colorMain_light;
    background-color: transparent;
  }

  &:active {
    color: $colorMain_dark;
    background-color: transparent;
  }
}

/* ////////// Shadow ////////// */

.button_shadow {
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);
}

/* ////////// Round ////////// */

.button_round {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin: 0 1px;
  padding: 0;
  font-size: 1.125rem;
  border: none;
  border-radius: 50%;
  text-align: center;
  color: $colorText;
  background-color: transparent;
  white-space: nowrap;
}

.button_round:hover {
  background-color: $colorBkg;
}

.button_round:focus {
  box-shadow: 0 0 0 3px $colorGrey_shadow;
  outline: none;
}

/* ////////// Full width ////////// */

.button_full {
  width: 100%;
}

/* ////////// Disabled ////////// */

.button:disabled {
  border-color: $colorDisabled;
  background-color: $colorDisabled;
  cursor: not-allowed;
}

/* ////////// With loader ////////// */

.button_load {
  min-width: 5.5rem;

  span .mdi-spin {
    color: $colorText;
  }
}

/* //////////////////// Alert //////////////////// */
/* TODO : Redo as component in ui-components */

.alert {
  display: flex;
  padding: 0.75rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.alert_danger {
  background-color: $colorDanger_altBkg;
  color: $colorDanger_altText;
}

.alert__icon {
  margin-right: 0.75rem;
  font-size: 1rem;
}

.alert__title,
.alert__text {
  margin: 0;
  font-size: 0.875rem;
}

/* //////////////////// Card //////////////////// */

.card {
  border-radius: 0;
  background-color: white;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.authCard {
  width: calc(100% - 3rem);
  max-width: 360px;
  margin: 1.5rem;
}

.cardHead {
  margin: 0;
  padding: 1.5rem;
  border-bottom: 1px solid $colorOutline;
}

.authCardHead {
  display: flex;
  align-items: center;
  border: none;
  background-color: $colorMain;
}

.authCardLogo {
  display: block;
  max-width: 50%;
  width: 140px;
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 2px solid rgb(255 255 255 / 25%);
}

.authCardSubtitle {
  font-size: 1rem;
  color: white;
  font-weight: normal;
}

.cardBody {
  padding: 1.5rem;
}

.cardTitle {
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
}

.cardTitle.cardTitle_small {
  font-size: 0.875rem;
}

/* //////////////////// Input //////////////////// */

.fileInput {
  margin: 0.75rem 0 0 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #676767;
}

.imagePreview {
  max-width: 25%;
  height: auto;
  margin-top: 0.75rem;
}

/* ////////// Container ////////// */

.inputCont {
  margin-bottom: 2rem;
}

.inputCont_form {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: $mediaqueryS) {
  .inputCont_form {
    flex-direction: column;
  }
}

.inputCont_iconLeft {
  position: relative;

  .iconLeft {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    font-size: 1.125rem;
    color: $colorTextLight;
    pointer-events: none;
  }

  input {
    padding-left: 2.5rem;
  }
}

/* ////////// Label ////////// */

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: $colorTextLight;
}

.label.label_large {
  font-size: 0.875rem;
  font-weight: bold;
  color: $colorMain;
}

.inputCont_form .label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;
  min-height: 2.25rem;
  margin: 0 1rem 0 0;
  font-weight: bold;
  color: $colorText;
}

@media screen and (max-width: $mediaqueryS) {
  .inputCont_form .label {
    justify-content: flex-start;
    width: 100%;
    min-height: 0;
    margin-bottom: 0.5rem;
  }
}

.label_bold {
  font-size: 0.875rem;
  font-weight: bold;
  color: $colorMain;
}

/* ////////// Icon ////////// */

.inputIconCont {
  position: relative;
}

.inputIconField {
  padding-right: 2.5rem;
}

.inputIconBtn {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  border: none;
  border-radius: 1000px;
  font-size: 1rem;
  color: $colorTextLight;
  background-color: transparent;
  transition: box-shadow 200ms;
}

.inputIconBtn:focus {
  box-shadow: 0 0 0 3px $colorOutline;
  outline: none;
}

/* ////////// Checkbox ////////// */

.checkboxCont {
  display: flex;
  align-items: center;
}

.checkboxCont_large {
  align-items: flex-start;
}

.checkboxLabel {
  padding: 0.75rem 1rem;
  border: solid 1px $colorOutline;
  margin-right: 1rem;
  cursor: pointer;
}

.checkboxCont_large .checkboxLabel {
  padding: 0;
}

.checkboxLabel__title {
  display: block;
  font-size: 0.875rem;
  color: $colorText;

  i {
    margin-right: 0.25rem;
  }
}

.checkboxLabel__subtitle {
  color: $colorTextLight;
}

/* ////////// Form ////////// */

.inputCont__end {
  display: flex;
  width: 75%;
  min-height: 2.25rem;
}

@media screen and (max-width: $mediaqueryS) {
  .inputCont__end {
    width: 100%;
    min-height: 0;
  }
}

/* //////////////////// Containers //////////////////// */

.privateBaseCont {
  position: relative;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
}

.bkgCont {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.fixedAside {
  position: sticky;
  top: calc(48px + 7.5rem);
  z-index: 900;
}

@media screen and (max-width: $mediaqueryM) {
  .privateBaseCont {
    min-height: calc(100vh - 52px);
    margin-top: 52px;
  }

  .fixedAside {
    position: static;
  }
}

/* //////////////////// Dropdown //////////////////// */

.p-overlaypanel.dropdown {
  min-width: 200px;
  margin-top: 0;
  border-radius: 0;
  text-align: left;
  z-index: 800 !important;

  &::before,
  &::after {
    display: none;
  }

  .p-overlaypanel-content {
    padding: 0;
  }
}

.dropdownList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: Heebo, sans-serif;
}

.dropdownList_margin {
  margin: 0.5rem 0;
}

.dropdownList__title {
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.75rem 1rem 0.25rem 1rem;
  color: $colorHeaderTextLight;
}

.dropdownList__btn {
  @include unstyledButton;
  position: relative;
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  text-align: left;
  transition: background-color 200ms, box-shadow 200ms;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    background-color: $colorHeaderHover;
  }

  &:focus {
    box-shadow: 0 0 0 2px $colorHeaderTextLight;
  }

  .headerNavBadge {
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
  }
}

.dropdownList__btn_bold {
  font-weight: bold;
}

.dropdownList__btn_danger {
  color: $colorDanger;
}

.dropdownList__btn_borderTop {
  border-top: 1px solid $colorHeaderOutline;
}

.dropdownList__btn_last {
  border-radius: 0;
}

.dropdownList__btn__icon {
  width: 1rem;
  margin-right: 0.75rem;
}

/* //////////////////// Modal //////////////////// */

.modalContent {
  max-width: 480px;
  color: $colorText;
}

.modalFoot {
  display: flex;
  justify-content: space-between;
}

.modalFoot_sticky {
  position: sticky;
  bottom: 0;
  padding: 1rem;
  margin: -1rem;
  background: white;
  border-radius: 0;
  box-shadow: 0 -3px 12px rgba(0, 0, 0, 15%);
}

/* //////////////////// Table //////////////////// */

.iconCell {
  width: 2.5rem;
}

.buttonCell,
.p-datatable .p-datatable-tbody > tr > td.buttonCell {
  width: 3.5rem;
  padding: 0 0.75rem;
}

.p-datatable .p-datatable-tbody > tr > td.emptyTableMsg {
  text-align: center;

  i {
    margin-right: 0.25rem;
  }
}

@media screen and (max-width: 40em) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:first-of-type.iconCell {
    width: 2.5rem;
    clear: none;
    padding: 0.75rem;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.titleCell {
    width: calc(100% - 2.5rem);
    clear: right;
    padding: 0.75rem;
    font-weight: bold;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.titleCell_icon {
    width: calc(100% - 5rem);
    padding-left: 0;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.buttonCell_mobileTop {
    border-bottom: 1px solid $colorOutline;
    padding: 0 0 0.5rem 0;

    .button {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr:last-of-type > td.buttonCell_mobileTop {
    border-bottom-color: transparent;
  }
}

/* //////////////////// History //////////////////// */

.toggleHistoryBtn {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  bottom: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: 1000px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.closeHistoryBtn {
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@media screen and (max-width: $mediaqueryS) {
  .historyCard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 1200;
    transform: translateX(100%);
    transition: transform 300ms;
  }

  .historyCard_visible {
    transform: translateX(0);
  }

  .toggleHistoryBtn {
    display: flex;
  }

  .closeHistoryBtn {
    display: block;
  }
}

/* //////////////////// Input error //////////////////// */

.p-error_tooltip {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0;
  color: $colorTextInverse;
  background-color: $colorDanger;
}

/* //////////////////// Accordion //////////////////// */

.accordionCardInstr {
  margin: 0 0 1rem 0;
  font-size: 0.875rem;
  color: $colorTextLight;
}

.searchAddInput {
  width: 100%;
  max-width: 420px;
}
/* //////////////////// Butons Siges //////////////////// */

.button-actions-business-actor {
  background-color: $tspSigesBlue;
  color: $tspSigesWhite;
  margin-right: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.button-actions-small-delete {
  background-color: $tspSigesError;
  color: $tspSigesWhite;
  margin-right: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.button-container{
  display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -5px;
}


.icon-small{
  font-size: 20px;
  margin-right: -7px;
}

// Variables
//@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Kaushan+Script&family=Open+Sans:wght@700&family=Red+Hat+Display:wght@400;700;900&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Kanit');

$bodyfont: 'Kanit', sans-serif;
$headingfont: 'Kanit', sans-serif;

//@import '~@angular/material/theming';

/*Material Theme Colors*/

/* $primary: mat-palette($mat-blue, 600);
$accent: mat-palette($mat-indigo, 500);
$warn: mat-palette($mat-pink, 500);
$theme: mat-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground); */

/* @include mat-core();

$custom-typography: mat-typography-config(
  $font-family: 'Poppins, sans-serif'
);
 */
// Override the typography in the core CSS.
//@include mat-core($custom-typography);

// Default Theme
//@include angular-material-theme($theme);

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

/* ////////////////////////////// Global styles ////////////////////////////// */ /* ////////////////////////////// SASS Variables ////////////////////////////// */
/*////////// Colors //////////*/
/*///// General /////*/
/*Buttons*/
/*///// Letras /////*/
/*///// EstadosLetras (Activo-Vigente)-Expirar-(Caducado-de baja) /////*/
/*/ Main /*/
/*/ Main alt /*/
/*/ Success /*/
/*/ Info /*/
/*/ Warning /*/
/*/ Danger /*/
/*/ Text /*/
/*/ Other /*/
/*///// Header /////*/
/*////////// Colors Dark mode DM //////////*/
/*/ General /*/
/*/ Header dark /*/
/*////////// Measurements //////////*/
/*////////// Media Queries //////////*/
/* Media queries should always be used with max-width. */
/* ////////////////////////////// SASS Variables ////////////////////////////// */
/*////////// Colors //////////*/
/*///// General /////*/
/*Buttons*/
/*///// Letras /////*/
/*///// EstadosLetras (Activo-Vigente)-Expirar-(Caducado-de baja) /////*/
/*/ Main /*/
/*/ Main alt /*/
/*/ Success /*/
/*/ Info /*/
/*/ Warning /*/
/*/ Danger /*/
/*/ Text /*/
/*/ Other /*/
/*///// Header /////*/
/*////////// Colors Dark mode DM //////////*/
/*/ General /*/
/*/ Header dark /*/
/*////////// Measurements //////////*/
/*////////// Media Queries //////////*/
/* Media queries should always be used with max-width. */
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,300&display=swap");
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~bootstrap/dist/css/bootstrap.css';
body {
  font-family: "Kanit", sans-serif !important;
}

.tsp-title {
  font-family: "Kanit", sans-serif !important;
  text-transform: uppercase;
  font-size: 1.325rem;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
}

.tsp-title-modals, .tsp-title-header-modal {
  font-family: "Kanit", sans-serif !important;
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
  text-align: start !important;
}

.ks-line {
  width: 98%;
  background-color: #0422d8;
  color: #fff;
  height: 2px;
  position: relative;
  opacity: 100%;
  border-radius: 2px;
  margin-top: 1px;
  margin-bottom: 6px;
}

.date-label {
  color: rgba(0, 0, 0, 0.6);
}

.filter-container {
  border: 2px solid #0422d8;
  margin-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  border-radius: 5px;
}

.tsp-secction-title {
  color: #0422d8;
  font-weight: 500;
  text-transform: uppercase;
}

.view-title-container {
  background-color: #0422d8;
  padding: 10px;
}

.tsp-button-clone {
  background-color: #0277bd !important;
  color: #fff !important;
}

.tsp-button-edit {
  background-color: #ffb300 !important;
  color: #fff !important;
}

.tsp-button-delete {
  background-color: #b3261e !important;
  color: #fff !important;
}

.tsp-button-magenta {
  background-color: #ff0071 !important;
  color: #fff !important;
}

.tsp-button-success {
  background-color: #0422d8 !important;
  color: #fff !important;
}

.tsp-button-tool {
  background-color: #5e35b1 !important;
  color: #fff !important;
}

.tsp-title-header-modal {
  background-color: #0422d8;
  color: #fff;
  margin-left: -16px;
  margin-right: -16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-dialog-content {
  margin: 0 0;
  padding: 0 0;
}

@media screen and (min-width: 0px) {
  .table-font-header {
    font-size: 1.5vh !important;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-default-content {
    font-size: 1.8vh !important;
    font-family: "Kanit", sans-serif !important;
    text-transform: uppercase;
  }
  .tsp-font-size-default-title {
    font-size: 1.5vh !important;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-buttons {
    font-size: 1.5vh !important;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-modal-titles {
    font-size: 1.8vh;
    font-family: "Kanit", sans-serif !important;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 600;
  }
}
@media screen and (min-width: 768px) {
  .table-font-header {
    font-size: 1.5vh !important;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-default-content {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-default-title {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-buttons {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-modal-titles {
    font-size: 1.8vh;
    font-family: "Kanit", sans-serif !important;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 600;
  }
}
@media screen and (min-width: 992px) {
  .table-font-header {
    font-size: 1.5vh !important;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-default-content {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-default-title {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-buttons {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-modal-titles {
    font-size: 1.8vh;
    font-family: "Kanit", sans-serif !important;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1200px) {
  .table-font-header {
    font-size: 1.5vh !important;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-default-content {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-default-title {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-buttons {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-size-modal-titles {
    font-size: 1.6vh;
    font-family: "Kanit", sans-serif !important;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 400;
  }
  .tsp-font-size-errors-messages {
    font-size: 1.3vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-title-menu {
    font-size: 1.5vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-sub-title-menu {
    font-size: 1.4vh;
    font-family: "Kanit", sans-serif !important;
  }
  .tsp-font-headers-tables-xs {
    font-size: 12px;
    font-family: "Kanit", sans-serif !important;
    line-height: 10px;
    text-align: center;
    font-weight: 600;
  }
  .tsp-font-content-tables-xs {
    font-size: 12px;
    font-family: "Kanit", sans-serif !important;
    line-height: 10px;
    text-align: center;
  }
}
/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
.container::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.container::-webkit-scrollbar:vertical {
  width: 10px !important;
}

.container::-webkit-scrollbar-button:increment,
.container::-webkit-scrollbar-button {
  display: none !important;
}

.container::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background-color: #797979 !important;
  border-radius: 20px !important;
  border: 2px solid #f1f2f3 !important;
}

.container::-webkit-scrollbar-track {
  border-radius: 10px;
}

.container {
  padding-right: 10px !important;
  overflow-x: hidden !important;
}

.fuel-supply-consumption-status-acceptable {
  background-color: green;
  color: #fff;
  padding: 5%;
  border-radius: 4px;
  font-weight: 900;
}

.fuel-supply-consumption-status-unacceptable {
  background-color: red;
  color: #fff;
  padding: 5%;
  border-radius: 4px;
  font-weight: 900;
}

.pip-status-valid {
  background-color: green;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.pip-status-expire {
  background-color: #cca300;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.pip-status-lapsed {
  background-color: red;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.flag-status-valid {
  background-color: green;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.flag-status-expire {
  background-color: #cca300;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.flag-status-lapsed {
  background-color: red;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

.flag-status-registered {
  background-color: #cca300;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 2px 10px 2px 10px;
  max-width: 150px;
}

.flag-status-approved {
  background-color: #2e7d32;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 2px 10px 2px 10px;
  max-width: 150px;
}

.flag-status-expired {
  background-color: #b3261e;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 2px 10px 2px 10px;
  max-width: 150px;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0 !important;
}

.tsp-labels {
  font-weight: 600 !important;
}

/*-Buttons-*/
.tsp-small-icon-button {
  width: 27px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.tsp-small-icon-button > *[role=img] {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.tsp-small-icon-button > *[role=img] svg {
  width: 16px;
  height: 16px;
}
.tsp-small-icon-button .mat-mdc-button-touch-target {
  width: 24px !important;
  height: 24px !important;
}

.small-button {
  width: 36px;
  height: 36px;
  min-width: 38px !important;
  padding: 0px !important;
}

.tsp-row {
  display: flex;
  flex-wrap: wrap;
}

.txt-center {
  text-align: center;
}

/* //////////////////// Atomic //////////////////// */
.displayBlock {
  display: block;
}

.displayFlex {
  display: flex;
}

.displayNone {
  display: none;
}

.margin_0 {
  margin: 0 !important;
}

.marginTop_0 {
  margin-top: 0 !important;
}

.marginLeft_0 {
  margin-left: 0 !important;
}

.marginBottom_0 {
  margin-bottom: 0 !important;
}

.marginRight_0 {
  margin-right: 0 !important;
}

.margin_1 {
  margin: 1rem !important;
}

.marginTop_1 {
  margin-top: 1rem !important;
}

.marginLeft_1 {
  margin-left: 1rem !important;
}

.marginBottom_1 {
  margin-bottom: 1rem !important;
}

.marginRight_1 {
  margin-right: 1rem !important;
}

.margin_2,
.marginR_2 {
  margin: 1.5rem !important;
}

.marginTop_2,
.marginTopR_2 {
  margin-top: 1.5rem !important;
}

.marginLeft_2,
.marginLeftR_2 {
  margin-left: 1.5rem !important;
}

.marginBottom_2,
.marginBottomR_2 {
  margin-bottom: 1.5rem !important;
}

.marginRight_2,
.marginRightR_2 {
  margin-right: 1.5rem !important;
}

.margin_3 {
  margin: 2rem !important;
}

.marginTop_3 {
  margin-top: 2rem !important;
}

.marginLeft_3 {
  margin-left: 2rem !important;
}

.marginBottom_3 {
  margin-bottom: 2rem !important;
}

.marginRight_3 {
  margin-right: 2rem !important;
}

@media screen and (max-width: 767px) {
  .marginR_2 {
    margin: 1rem !important;
  }
  .marginTopR_2 {
    margin-top: 1rem !important;
  }
  .marginLeftR_2 {
    margin-left: 1rem !important;
  }
  .marginBottomR_2 {
    margin-bottom: 1rem !important;
  }
  .marginRightR_2 {
    margin-right: 1rem !important;
  }
}
.padding_0 {
  padding: 0 !important;
}

.paddingTop_0 {
  padding-top: 0 !important;
}

.paddingLeft_0 {
  padding-left: 0 !important;
}

.paddingBottom_0 {
  padding-bottom: 0 !important;
}

.paddingRight_0 {
  padding-right: 0 !important;
}

.padding_1 {
  padding: 1rem !important;
}

.paddingTop_1 {
  padding-top: 1rem !important;
}

.paddingLeft_1 {
  padding-left: 1rem !important;
}

.paddingBottom_1 {
  padding-bottom: 1rem !important;
}

.paddingRight_1 {
  padding-right: 1rem !important;
}

.padding_2 {
  padding: 1.5rem !important;
}

.paddingTop_2 {
  padding-top: 1.5rem !important;
}

.paddingLeft_2 {
  padding-left: 1.5rem !important;
}

.paddingBottom_2 {
  padding-bottom: 1.5rem !important;
}

.paddingRight_2 {
  padding-right: 1.5rem !important;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.fontNormal {
  font-weight: normal;
}

.fontBold {
  font-weight: bold;
}

.colorText {
  color: #333333;
}

.colorTextLight {
  color: #676767;
}

.imgResponsive {
  width: 100%;
  height: auto;
}

/* //////////////////// Utilities //////////////////// */
.fullWidth {
  width: 100%;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #d9dce2;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: none;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

.unstyledButton {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: transparent;
  color: #333333;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;
}
.unstyledButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px #676767;
}

.greyBkg {
  min-height: calc(100vh - (6rem + 48px));
  background-color: #eaf2fa;
}

/* //////////////////// Custom scrollbar //////////////////// */
.elementScrollX {
  overscroll-behavior: contain;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
}

.elementScrollY {
  overscroll-behavior: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
}

.customScrollbar {
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.05);
}
.customScrollbar::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}
.customScrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
}
.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.customScrollbar::-webkit-scrollbar-thumb:active {
  background-color: rgb(0, 0, 0);
}
.customScrollbar::-webkit-scrollbar-thumb:vertical {
  min-height: 1.5rem;
}
.customScrollbar::-webkit-scrollbar-thumb:horizontal {
  min-width: 1.5rem;
}

/* //////////////////// General //////////////////// */
*,
::after,
::before {
  box-sizing: border-box;
}

html {
  padding-bottom: 0;
}

body {
  font-family: Heebo, sans-serif;
  color: #333333;
}

/* //////////////////// Text //////////////////// */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Overpass, sans-serif;
  font-weight: bold;
  line-height: 1.15;
}

/* //////////////////// Grid //////////////////// */
.container,
.container_fluid {
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 1rem;
}

.container_fluid {
  max-width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.col,
.col_1,
.col_2,
.col_3,
.col_4,
.col_5,
.col_6,
.col_7,
.col_8,
.col_9,
.col_10,
.col_11,
.col_12,
.col_lg_1,
.col_lg_2,
.col_lg_3,
.col_lg_4,
.col_lg_5,
.col_lg_6,
.col_lg_7,
.col_lg_8,
.col_lg_9,
.col_lg_10,
.col_lg_11,
.col_lg_12 {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding: 0 1rem;
}

.col {
  flex: 1 0 0px;
}

.col_1,
.col_2,
.col_3,
.col_4,
.col_5,
.col_6,
.col_7,
.col_8,
.col_9,
.col_10,
.col_11,
.col_12 {
  flex: 0 0 auto;
}

.col_1 {
  width: 8.33333%;
}

.col_2 {
  width: 16.66666%;
}

.col_3 {
  width: 25%;
}

.col_4 {
  width: 33.33333%;
}

.col_5 {
  width: 41.66666%;
}

.col_6 {
  width: 50%;
}

.col_7 {
  width: 58.33333%;
}

.col_8 {
  width: 66.66666%;
}

.col_9 {
  width: 75%;
}

.col_10 {
  width: 83.33333%;
}

.col_11 {
  width: 91.66666%;
}

.col_12 {
  width: 100%;
}

@media screen and (min-width: 991px) {
  .col_lg_1,
  .col_lg_2,
  .col_lg_3,
  .col_lg_4,
  .col_lg_5,
  .col_lg_6,
  .col_lg_7,
  .col_lg_8,
  .col_lg_9,
  .col_lg_10,
  .col_lg_11,
  .col_lg_12 {
    flex: 0 0 auto;
  }
  .col_lg_1 {
    width: 8.33333%;
  }
  .col_lg_2 {
    width: 16.66666%;
  }
  .col_lg_3 {
    width: 25%;
  }
  .col_lg_4 {
    width: 33.33333%;
  }
  .col_lg_5 {
    width: 41.66666%;
  }
  .col_lg_6 {
    width: 50%;
  }
  .col_lg_7 {
    width: 58.33333%;
  }
  .col_lg_8 {
    width: 66.66666%;
  }
  .col_lg_9 {
    width: 75%;
  }
  .col_lg_10 {
    width: 83.33333%;
  }
  .col_lg_11 {
    width: 91.66666%;
  }
  .col_lg_12 {
    width: 100%;
  }
}
/* //////////////////// Button //////////////////// */
/* ////////// Base ////////// */
.button {
  padding: 0.625rem 1rem;
  border: 2px solid #031640;
  border-radius: 0;
  font-size: 1rem;
  font-weight: normal;
  color: white;
  background-color: #031640;
  box-shadow: none;
  outline: none;
  transition: border 200ms, color 200ms, background-color 200ms, box-shadow 200ms;
}
.button:not(:disabled) {
  cursor: pointer;
}
.button:hover {
  border-color: #336699;
  background-color: #336699;
}
.button:active {
  border-color: #030f29;
  background-color: #030f29;
}
.button:focus {
  box-shadow: 0 0 0 3px rgba(3, 22, 64, 0.5);
  outline: none;
}
.button i {
  line-height: 1;
}

.buttonIcon_right {
  margin-right: 0.375rem;
}

/* ////////// Color ////////// */
.button_colorAlt {
  border-color: #336699;
  background-color: #336699;
}
.button_colorAlt:hover {
  border-color: #96cbff;
  background-color: #96cbff;
}
.button_colorAlt:active {
  border-color: #031640;
  background-color: #031640;
}
.button_colorAlt:focus {
  box-shadow: 0 0 0 3px rgba(51, 102, 153, 0.5);
}

.button_colorSuccess {
  border-color: #009567;
  background-color: #009567;
}
.button_colorSuccess:hover {
  border-color: #00b97f;
  background-color: #00b97f;
}
.button_colorSuccess:active {
  border-color: #00744f;
  background-color: #00744f;
}
.button_colorSuccess:focus {
  box-shadow: 0 0 0 3px rgba(0, 149, 103, 0.5);
}

.button_colorWarning {
  border-color: #febd40;
  color: #333333;
  background-color: #febd40;
}
.button_colorWarning:hover {
  border-color: #ffcb67;
  background-color: #ffcb67;
}
.button_colorWarning:active {
  border-color: #eaa41e;
  background-color: #eaa41e;
}
.button_colorWarning:focus {
  box-shadow: 0 0 0 3px rgba(254, 189, 64, 0.5);
}

.button_colorDanger {
  border-color: #ff074c;
  background-color: #ff074c;
}
.button_colorDanger:hover {
  border-color: #ff9696;
  background-color: #ff9696;
}
.button_colorDanger:active {
  border-color: #cf003b;
  background-color: #cf003b;
}
.button_colorDanger:focus {
  box-shadow: 0 0 0 3px rgba(255, 7, 76, 0.5);
}

.button_colorGrey {
  border-color: #ff074c;
  background-color: #ff074c;
}
.button_colorGrey:hover {
  border-color: #ff9696;
  background-color: #ff9696;
}
.button_colorGrey:active {
  border-color: #cf003b;
  background-color: #cf003b;
}
.button_colorGrey:focus {
  box-shadow: 0 0 0 3px rgba(255, 7, 76, 0.5);
}

/* ////////// Block ////////// */
.button_block {
  width: 100%;
}

/* ////////// Big ////////// */
.button_big {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
}

/* ////////// Small ////////// */
.button_small {
  font-size: 0.875rem;
}

/* ////////// Outline ////////// */
.button_outline {
  border-color: rgba(3, 22, 64, 0.2);
  color: #031640;
  background-color: transparent;
}
.button_outline:hover {
  border-color: #031640;
  color: white;
  background-color: #031640;
}
.button_outline:active {
  border-color: #336699;
  color: white;
  background-color: #336699;
}
.button_outline:focus {
  box-shadow: 0 0 0 3px rgba(3, 22, 64, 0.5);
}
.button_outline.button_colorGrey {
  border-color: #d9dce2;
  color: #333333;
}
.button_outline.button_colorGrey:hover {
  border-color: #d9dce2;
  color: #333333;
  background-color: #eaf2fa;
}
.button_outline.button_colorGrey:active {
  border-color: #d9dce2;
  color: #333333;
  background-color: #d9dce2;
}
.button_outline.button_colorGrey:focus {
  box-shadow: 0 0 0 3px rgba(123, 123, 123, 0.5);
}

/* ////////// Clear ////////// */
.button_clear {
  padding: 0.25rem 0.375rem;
  font-weight: bold;
  color: #031640;
  background-color: transparent;
}
.button_clear:hover {
  color: #336699;
  background-color: transparent;
}
.button_clear:active {
  color: #030f29;
  background-color: transparent;
}

/* ////////// Shadow ////////// */
.button_shadow {
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);
}

/* ////////// Round ////////// */
.button_round {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin: 0 1px;
  padding: 0;
  font-size: 1.125rem;
  border: none;
  border-radius: 50%;
  text-align: center;
  color: #333333;
  background-color: transparent;
  white-space: nowrap;
}

.button_round:hover {
  background-color: #eaf2fa;
}

.button_round:focus {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
  outline: none;
}

/* ////////// Full width ////////// */
.button_full {
  width: 100%;
}

/* ////////// Disabled ////////// */
.button:disabled {
  border-color: #c8c8c8;
  background-color: #c8c8c8;
  cursor: not-allowed;
}

/* ////////// With loader ////////// */
.button_load {
  min-width: 5.5rem;
}
.button_load span .mdi-spin {
  color: #333333;
}

/* //////////////////// Alert //////////////////// */
/* TODO : Redo as component in ui-components */
.alert {
  display: flex;
  padding: 0.75rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.alert_danger {
  background-color: #ffe8e8;
  color: #a52929;
}

.alert__icon {
  margin-right: 0.75rem;
  font-size: 1rem;
}

.alert__title,
.alert__text {
  margin: 0;
  font-size: 0.875rem;
}

/* //////////////////// Card //////////////////// */
.card {
  border-radius: 0;
  background-color: white;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.authCard {
  width: calc(100% - 3rem);
  max-width: 360px;
  margin: 1.5rem;
}

.cardHead {
  margin: 0;
  padding: 1.5rem;
  border-bottom: 1px solid #d9dce2;
}

.authCardHead {
  display: flex;
  align-items: center;
  border: none;
  background-color: #031640;
}

.authCardLogo {
  display: block;
  max-width: 50%;
  width: 140px;
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 2px solid rgba(255, 255, 255, 0.25);
}

.authCardSubtitle {
  font-size: 1rem;
  color: white;
  font-weight: normal;
}

.cardBody {
  padding: 1.5rem;
}

.cardTitle {
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
}

.cardTitle.cardTitle_small {
  font-size: 0.875rem;
}

/* //////////////////// Input //////////////////// */
.fileInput {
  margin: 0.75rem 0 0 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #676767;
}

.imagePreview {
  max-width: 25%;
  height: auto;
  margin-top: 0.75rem;
}

/* ////////// Container ////////// */
.inputCont {
  margin-bottom: 2rem;
}

.inputCont_form {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .inputCont_form {
    flex-direction: column;
  }
}
.inputCont_iconLeft {
  position: relative;
}
.inputCont_iconLeft .iconLeft {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  font-size: 1.125rem;
  color: #676767;
  pointer-events: none;
}
.inputCont_iconLeft input {
  padding-left: 2.5rem;
}

/* ////////// Label ////////// */
.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #676767;
}

.label.label_large {
  font-size: 0.875rem;
  font-weight: bold;
  color: #031640;
}

.inputCont_form .label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;
  min-height: 2.25rem;
  margin: 0 1rem 0 0;
  font-weight: bold;
  color: #333333;
}

@media screen and (max-width: 767px) {
  .inputCont_form .label {
    justify-content: flex-start;
    width: 100%;
    min-height: 0;
    margin-bottom: 0.5rem;
  }
}
.label_bold {
  font-size: 0.875rem;
  font-weight: bold;
  color: #031640;
}

/* ////////// Icon ////////// */
.inputIconCont {
  position: relative;
}

.inputIconField {
  padding-right: 2.5rem;
}

.inputIconBtn {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  border: none;
  border-radius: 1000px;
  font-size: 1rem;
  color: #676767;
  background-color: transparent;
  transition: box-shadow 200ms;
}

.inputIconBtn:focus {
  box-shadow: 0 0 0 3px #d9dce2;
  outline: none;
}

/* ////////// Checkbox ////////// */
.checkboxCont {
  display: flex;
  align-items: center;
}

.checkboxCont_large {
  align-items: flex-start;
}

.checkboxLabel {
  padding: 0.75rem 1rem;
  border: solid 1px #d9dce2;
  margin-right: 1rem;
  cursor: pointer;
}

.checkboxCont_large .checkboxLabel {
  padding: 0;
}

.checkboxLabel__title {
  display: block;
  font-size: 0.875rem;
  color: #333333;
}
.checkboxLabel__title i {
  margin-right: 0.25rem;
}

.checkboxLabel__subtitle {
  color: #676767;
}

/* ////////// Form ////////// */
.inputCont__end {
  display: flex;
  width: 75%;
  min-height: 2.25rem;
}

@media screen and (max-width: 767px) {
  .inputCont__end {
    width: 100%;
    min-height: 0;
  }
}
/* //////////////////// Containers //////////////////// */
.privateBaseCont {
  position: relative;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
}

.bkgCont {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.fixedAside {
  position: sticky;
  top: calc(48px + 7.5rem);
  z-index: 900;
}

@media screen and (max-width: 991px) {
  .privateBaseCont {
    min-height: calc(100vh - 52px);
    margin-top: 52px;
  }
  .fixedAside {
    position: static;
  }
}
/* //////////////////// Dropdown //////////////////// */
.p-overlaypanel.dropdown {
  min-width: 200px;
  margin-top: 0;
  border-radius: 0;
  text-align: left;
  z-index: 800 !important;
}
.p-overlaypanel.dropdown::before, .p-overlaypanel.dropdown::after {
  display: none;
}
.p-overlaypanel.dropdown .p-overlaypanel-content {
  padding: 0;
}

.dropdownList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: Heebo, sans-serif;
}

.dropdownList_margin {
  margin: 0.5rem 0;
}

.dropdownList__title {
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.75rem 1rem 0.25rem 1rem;
  color: #7b7b7b;
}

.dropdownList__btn {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  font-family: Heebo, sans-serif;
  text-decoration: none;
  background: transparent;
  color: #333333;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: box-shadow 200ms;
  position: relative;
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  text-align: left;
  transition: background-color 200ms, box-shadow 200ms;
}
.dropdownList__btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px #676767;
}
.dropdownList__btn:not(:disabled) {
  cursor: pointer;
}
.dropdownList__btn:hover {
  background-color: #cbe5ff;
}
.dropdownList__btn:focus {
  box-shadow: 0 0 0 2px #7b7b7b;
}
.dropdownList__btn .headerNavBadge {
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
}

.dropdownList__btn_bold {
  font-weight: bold;
}

.dropdownList__btn_danger {
  color: #ff074c;
}

.dropdownList__btn_borderTop {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.dropdownList__btn_last {
  border-radius: 0;
}

.dropdownList__btn__icon {
  width: 1rem;
  margin-right: 0.75rem;
}

/* //////////////////// Modal //////////////////// */
.modalContent {
  max-width: 480px;
  color: #333333;
}

.modalFoot {
  display: flex;
  justify-content: space-between;
}

.modalFoot_sticky {
  position: sticky;
  bottom: 0;
  padding: 1rem;
  margin: -1rem;
  background: white;
  border-radius: 0;
  box-shadow: 0 -3px 12px rgba(0, 0, 0, 0.15);
}

/* //////////////////// Table //////////////////// */
.iconCell {
  width: 2.5rem;
}

.buttonCell,
.p-datatable .p-datatable-tbody > tr > td.buttonCell {
  width: 3.5rem;
  padding: 0 0.75rem;
}

.p-datatable .p-datatable-tbody > tr > td.emptyTableMsg {
  text-align: center;
}
.p-datatable .p-datatable-tbody > tr > td.emptyTableMsg i {
  margin-right: 0.25rem;
}

@media screen and (max-width: 40em) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:first-of-type.iconCell {
    width: 2.5rem;
    clear: none;
    padding: 0.75rem;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.titleCell {
    width: calc(100% - 2.5rem);
    clear: right;
    padding: 0.75rem;
    font-weight: bold;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.titleCell_icon {
    width: calc(100% - 5rem);
    padding-left: 0;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.buttonCell_mobileTop {
    border-bottom: 1px solid #d9dce2;
    padding: 0 0 0.5rem 0;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.buttonCell_mobileTop .button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr:last-of-type > td.buttonCell_mobileTop {
    border-bottom-color: transparent;
  }
}
/* //////////////////// History //////////////////// */
.toggleHistoryBtn {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  bottom: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: 1000px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.closeHistoryBtn {
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@media screen and (max-width: 767px) {
  .historyCard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 1200;
    transform: translateX(100%);
    transition: transform 300ms;
  }
  .historyCard_visible {
    transform: translateX(0);
  }
  .toggleHistoryBtn {
    display: flex;
  }
  .closeHistoryBtn {
    display: block;
  }
}
/* //////////////////// Input error //////////////////// */
.p-error_tooltip {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0;
  color: white;
  background-color: #ff074c;
}

/* //////////////////// Accordion //////////////////// */
.accordionCardInstr {
  margin: 0 0 1rem 0;
  font-size: 0.875rem;
  color: #676767;
}

.searchAddInput {
  width: 100%;
  max-width: 420px;
}

/* //////////////////// Butons Siges //////////////////// */
.button-actions-business-actor {
  background-color: #0422d8;
  color: #fff;
  margin-right: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.button-actions-small-delete {
  background-color: #b3261e;
  color: #fff;
  margin-right: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
}

.icon-small {
  font-size: 20px;
  margin-right: -7px;
}